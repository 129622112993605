// import { axios } from "vue/types/umd";

export default {
  namespaced: true,

  state: () => ({
    departments:[],
    posts:[],
    images:[],
    current_posts: [],
    post: {},
    loader:false,
  }),

  getters: {
    departments(state) {
      return state.departments;
    },
    posts(state) {
      return state.posts;
    },
    current_posts(state) {
      return state.current_posts;
    },
    images(state) {
      return state.images;
    },
    post(state) {
      return state.post;
    },
  },

  mutations: {
    populateDepartments(state,newDepartment){
      state.departments = newDepartment;
    },
    populatePosts(state,newPost){
      state.posts = newPost;
      state.current_posts = newPost;
    },
    populateImages(state,newImage){
      state.images = newImage;
    },
    populatePost(state,newPostDetails){
      state.post = newPostDetails;
    },
    LOADER(state,payload){
      state.loader = payload
    },
  },

  actions: {
    getDepartments({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('departments')
    .then(res => {
        resolve(res);
        commit("populateDepartments",res.data.departments);
    })
        .catch((error) => {
          reject(error);
        });
    });
    },
    getPosts({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('job_posts')
    .then(res => {
        resolve(res);
        commit("populatePosts",res.data.posts);
    })
        .catch((error) => {
          reject(error);
        });
    });
    },
    GalleryImages({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('gallery_images')
    .then(res => {
        resolve(res);
        commit("populateImages",res.data.images);
    })
        .catch((error) => {
          reject(error);
        });
    });
    },
    getPostDetails({commit},id) {
      return new Promise((resolve, reject) => {
        axios.get('job_post/'+id)
    .then(res => {
        resolve(res);
        commit("populatePost",res.data.post);
    })
        .catch((error) => {
          reject(error);
        });
    });
    },
    saveApplication({commit},data) {
      return new Promise((resolve, reject) => {
        axios.post('job_post_application',data)
    .then(res => {
        resolve(res);
    })
        .catch((error) => {
          reject(error);
        });
    });
    },
  }
};
