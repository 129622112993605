<template>
  <main class="job-form-1">
    <div class="page-heading">
      <div class="container">
        <div>
          <h3 class="text-center mb-2">Job Application Form</h3>
          <h4 class="text-center text-muted">
            <span>{{this.$route.params.name}}</span>
          </h4>
        </div>
      </div>
    </div>

    <div class="career-f" v-if="!success">
      <div class="container">
        <div v-if="error" class="align-center p-1 mb-2" style="background-color:red">
                        <a class="ml-5"><span style="color:white">{{error}}</span></a>
                    </div>
        <v-form ref="regForm" v-model="valid" lazy-validation>
          <div class="personal_information mb-35">
            <div class="form-h">
              <h3>
                <u>Personal information</u>
              </h3>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label" for="name"
                  >Your Name</label
                >
              </div>
              <div class="col-md-7 col-sm-8">
                <v-text-field
                  type="text"
                  v-model="form.name"
                  dense
                  required
                  :rules="nameRules"
                  height="30"
                  outlined
                > </v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label" for="partner_email"
                  >Your Email</label
                >
              </div>
              <div class="col-md-7 col-sm-8">
                <v-text-field
                  type="email"
                  v-model="form.email"
                  :rules="emailRules"
                  dense
                  outlined
                  required
                ></v-text-field>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label" for="partner_phone"
                  >Your Phone Number</label
                >
              </div>
              <div class="col-md-7 col-sm-8">
                <v-text-field
                  type="tel"
                  v-model="form.phone"
                  :rules="phoneRules"
                  required
                  dense
                  outlined
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                ></v-text-field>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label" for="partner_uni"
                  >Your University</label
                >
              </div>
              <div class="col-md-7 col-sm-8">
                <v-text-field
                  type="text"
                  v-model="form.university"
                  required
                  :rules="commonRules"
                  dense
                  outlined
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="personal_experience mb-35">
            <div class="form-h">
              <h3>
                <u>Professional information</u>
              </h3>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label" for="partner_exp"
                  >Total Years of Experience</label
                >
              </div>
              <div class="col-md-7 col-sm-8">
                <v-text-field
                  type="text"
                  v-model="form.experience"
                  dense
                  :rules="commonRules"
                  outlined
                  required
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label" for="expertise_or_interest"
                  >Expertise/Interest Areas</label
                >
              </div>
              <div class="col-md-7 col-sm-8">
                <v-textarea
                  type="text"
                  v-model="form.expertise"
                  required
                  :rules="commonRules"
                  dense
                  outlined
                ></v-textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label" for="partner_notice"
                  >Notice Period</label
                >
              </div>
              <div class="col-md-7 col-sm-8">
                <v-select v-model="form.notice_period" :rules="commonRules" dense outlined :items="['Immediately', '15 days', '1 Month', '1+ Month']"
    label="select a notice period" required class="">
                  <!-- <option value="">select a notice period</option>
                  <option value="immediately">Immediately</option>
                  <option value="immediately">15 Days</option>
                  <option value="one_month">1 Month</option>
                  <option value="immediately">1+ Month</option> -->
                </v-select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label" for="partner_salary"
                  >Current Salary</label
                >
              </div>
              <div class="col-md-7 col-sm-8">
                <v-text-field
                  type="text"
                  v-model="form.present_salary"
                  required
                  dense
                  :rules="commonRules"
                  outlined
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label" for="expected_salary"
                  >Expected Salary</label
                >
              </div>
              <div class="col-md-7 col-sm-8">
                <v-text-field
                  type="text"
                  v-model="form.expected_salary"
                  required
                  :rules="commonRules"
                  dense
                  outlined
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label r-none" for="letter"
                  >Cover Letter</label
                >
              </div>
              <div class="col-md-7 col-sm-8">
                <v-textarea
                  type="text"
                  v-model="form.cover_letter"
                  dense
                  outlined
                ></v-textarea>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-3 col-sm-4 text-end">
                <label class="control-label r-none" for="Resume">Resume</label>
              </div>
              <div class="col-md-7 col-sm-8">
                <v-file-input
            type="file"
            v-model="resume"
            required
            :rules="commonRules"
            dense
            outlined
            label="Resume"
          ></v-file-input>
              </div>
              
            </div>
            <div class="row mb-5 mt-30">
              <div class="offset-3 col-md-7 col-sm-8">
                <v-btn class="cmn-btn-blue" :loading="loading"
            :disabled="!valid && loading"
            dark
            @click="saveApplication()">Submit</v-btn>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <div class="career-f" v-else>
      <div class="d-flex justify-content-center text-center align-items-center">
        <div class="card p-4 shadow">
          <h5 class="mb-2">Your Application has been Successfully Completed</h5>
          <p>Thank you for applying the position of {{this.$route.params.name}}</p>
          <p>We will contact with you very soon. </p>
          <v-btn class="cmn-btn-blue m-auto" width="100" @click="$this.$router.push('/')">Finish</v-btn>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "CareerApplicationPage",
  data: () => ({
    baseEnvLocal: "https://timerni.com/",
    valid: true,
    resume:null,
    success:false,
    form: {
      name: "",
      email: "",
      phone: "",
      experience: "",
      expertise: "",
      university: "",
      cover_letter: "",
      notice_period: "",
      present_salary: "",
      expected_salary: "",
    },
    loading: false,
    error: "",
    rules: {
      required: (value) => !!value || "Required.",
    },
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length >= 8 || "Name requires at least 3 characters",
    ],
    phoneRules: [
      (v) => !!v || "Phone is required",
      (v) => v.length >= 11 || "Phone requires at least 11 characters",
    ],
    commonRules: [
      (v) => !!v || "This Field is required",
    ],
  }),

  async created() {
    // this.getProfile();
  },
  computed: {
    // ...mapState("homepage", ["profile"]),
  },
  methods: {
    saveApplication()
    {
      this.error = ""
      this.loading = true
      let valid = true;
      this.$refs.regForm.validate();
      this.$refs.regForm.inputs.forEach((input) => {
        if (input.hasError) {
          this.loading = false
          valid = false;
        }
      });
      if (!valid) {
        this.loading = false
        return;
      }
      let formData = new FormData();
      formData.append('resume',this.resume);
      formData.append('post_id',this.$route.params.id);
      for (let field in this.form) {
        formData.append(field, this.form[field]);
      }
      this.$store.dispatch("homepage/saveApplication",formData).then(
        (response) => {
          this.loading = false
          if(response.error){
            this.error = response.error
          }
          else{
            this.reset()
            this.success = true
          }
          
        }
      )
        .catch(() => {
          this.loading = false
        });
    },
    reset() {
      this.$refs.regForm.reset();
    },
  },
};
</script>

<style>
.form-h h3 {
  margin-bottom: 25px;
  color: var(--s-primary-color);
  font-size: 22px;
}

.career-f .control-label:after {
  content: " *";
}

.career-f .control-label.r-none:after {
  content: " ";
}

.career-f .control-label {
  text-align: right;
  margin-bottom: 0;
  padding-top: 7px;
}

.career-f .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgb(13 110 253 / 25%);
}
.job-form-1 .cmn-btn-blue {
  border-radius: 0 !important;
  font-size: 15px !important;
}
</style>
