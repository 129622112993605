<template>
  <CareerDetailsPage />
</template>

<script>
import CareerDetailsPage from '../components/CareerDetailsPage'
import {mapActions,mapState} from "vuex"
export default {
  name: 'CareerDetails',
  components: {
    CareerDetailsPage,
  },
  async created() {

  },
  computed: {
  },
  methods: {

  }
}
</script>
<style>
.job-page-1 .heading>a {
            color: #312783;
            font-size: 18px;
        }
        
        .job-page-1 .job-heading {
            color: #666666;
            font-size: 28px;
        }
        
        .job-page-1 .job-s-d {
            font-size: 16px;
        }
        
        .job-page-1 .black-f {
            color: rgb(0, 0, 0);
            font-weight: 400;
            text-align:justify;
            font-size:14px;
        }
        
        .job-page-1 .task-wrapper h4.s-title {
            font-weight: 600;
            font-size: 18px;
        }
        
        .job-page-1 .task-wrapper ul li {
            color: rgb(0, 0, 0);
            font-size: 14px;
            padding-bottom: 8px;
            text-align:justify;
        }
        
        .job-page-1 .task-wrapper ul.disc-2,
        .job-page-1 .task-wrapper ul.disc {
            list-style-type: disc;
            padding-left: 2rem;
        }
        
        .task-wrapper ul.disc li::marker {
            color: rgb(102, 102, 102);
        }
        
        .job-page-1 .job-feature .f-content {
            text-align: center;
        }
        
        .job-page-1 .job-feature .f-content h3 {
            text-align: center;
            font-size: 17px;
            color: #666666;
            margin-bottom:5px;
        }
        
        .job-page-1 .job-feature .f-content>i {
            color: #312783;
        }
        
        .job-page-1 .border-2 {
            color: #666666;
            height: 3px;
            opacity: .5;
        }
        
        .job-page-1 .job-facility .f-content h3 {
            text-align: center;
            font-size: 17px;
            color: #666666;
            font-weight: 500;
            margin-bottom:5px;
        }
        .job-feature,.job-facility,.job-img,.job-hr{
          margin:30px 0;
        }
        .job-page-1 .job-facility .f-content>i {
            color: #312783;
        }
        
        .job-page-1 .job-hr .fa-mobile,
        .job-page-1 .job-hr .f-content>i {
            color: #666666;
        }
        
        .job-page-1 .job-hr h4 {
            color: #0B2E59;
            font-size: 17px;
            font-weight: 500;
            line-height: 1.5;
            padding-top: 4px;
        }
        
        .job-page-1 .job-hr .cont-j a,
        .job-page-1 .job-hr .cont-j p {
            color: #0B2E59;
            font-size: 15px;
            font-weight: 500;
            margin: 5px 0px;
        }
        .job-page-1 p{
          font-size:14px;
        }
        .job-page-1 .cmn-btn-blue
        {
          border-radius:0!important;
          font-size:15px!important;
        }
</style>