<template>
  <main class="job-page">
    <div class="page-heading">
      <div class="container">
        <div class="heading">
          <h2 class="s-heading">job</h2>
        </div>
      </div>
    </div>
    <!-- banner-slider start -->
    <section class="job-slide">
      <div
        class="banner banner-gif com-banner overlay"
        data-background="http://127.0.0.1:8000/assets/frontend/img/career/job-banner.gif"
        style="
          background-image: url('http://127.0.0.1:8000/assets/frontend/img/career/job-banner.gif');
        "
      >
        <div class="container">
          <div class="banner-heading text-center">
            <h1 class="banner-title">
              <span>Welcome To</span> Time research &amp; innovation
            </h1>
            <p class="t18 text-white">We are best in Research</p>
          </div>
        </div>
      </div>
      <!-- banner end -->
    </section>
    <!-- banner-slider end -->
    <section class="job-wrapper">
      <!-- job start -->
      <div class="job sec-padding-top">
        <div class="container">
          <div class="heading heading-left">
            <h2 class="s-heading">ABOUT CAREER</h2>
            <h4 class="s-sub-heading">
              Learn from <span class="s-sub-c"> real-time works.</span>
            </h4>
          </div>
          <div class="con-padding-top">
            <div class="row">
              <div class="col-md-12 col-lg-5">
                <div class="job-img c-content-img">
                  <img
                    :src="require('@/assets/img/about-career.png')"
                    alt="career"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="col-md-12 col-lg-7 d-xl-flex align-items-xl-center">
                <div class="job-content c-content">
                  <p class="text-justify">
                    Tri is proud to deliver one of the most attractive pay and
                    benefits packages in both the United Kingdom and Bangladesh.
                    The company provides a wide variety of health and wellness
                    programs to its staff, including medical, dental, vision,
                    life insurance, disability insurance, and flexible expense
                    plans for healthcare. Additional benefits include paid
                    maternity leave, a retirement package, and paid holidays.
                    The organisation also offers onsite and virtual training and
                    continuing education and a full range of health benefits to
                    achieve the perfect balance between success and
                    satisfaction. A person based in Bangladesh and the UK will
                    get the highest priority to apply for a position.
                    Nonetheless, anyone from any part of the world can work as a
                    work-based learner, trainee, and intern or full-time job. We
                    provide the best career growth opportunity for any of our

                    <span v-if="readMore"></span>
                    <span v-else>...</span>
                  </p>

                  <p class="text-justify" v-show="readMore">
                    employees, whether they have a little or vast experience. We
                    support our employees by giving industry-standard training
                    and learning environments to make them work efficiently. Our
                    priority is to take care of those who have not yet finished
                    their bachelor studies, especially those who are at the
                    college or university level and need industry experience to
                    get a dream job. Our paid (scholarship) and self-paid (paid
                    by the candidate) intern, work-based learning facility will
                    build your knowledge and boost confidence level. After
                    completing your training, you will be able to work for any
                    international standard company in Bangladesh and abroad.
                    These courses are varied from three months to a year based
                    on your knowledge and experience. Employees have a wide
                    variety of occupations to choose from with various career
                    categories within Tri. If you specialise in software
                    development, artificial intelligence, computer networking,
                    research, business &amp; finance, or advertisement,
                    candidates can find a role that is suitable for them. We
                    offer career paths for students and experienced
                    professionals and training facilities. You can read more
                    about our ongoing development and research projects to find
                    out our initiatives and activities. If any of these get your
                    attention, please feel free to ask us a question.
                  </p>
                  <button class="cmn-btn-blue mt-2" :style="{fontSize:'12px',padding:'6px 15px'}" @click="readMore = !readMore">
                    <span v-if="readMore">Read Less</span>
                    <span v-else>Read More</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- mt-35 end -->
        </div>
        <!-- container -->
      </div>
      <!-- job end -->
    </section>

    <!-- current start -->
    <section class="current-job sec-padding-top">
      <div class="container position-relative">
        <div class="heading">
          <h2 class="s-heading">Current Job</h2>
        </div>
        <!-- heading end -->
        <div class="mt-5 current-job-wrapper">
          <div class="job-select d-flex align-items-center justify-content-end">
            <div class="dropdown d-lg-block mr-30">
              <a
                href="#"
                class="flag-logo toggle-none dropdown-toggle pb-2"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img :src="require('@/assets/img/flag.png')" alt="flag" />
                <i class="fas fa-chevron-down"></i>
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="#">
                    <img :src="require('@/assets/img/flag.png')" alt="flag" />
                  </a>
                </li>
              </ul>
            </div>
            <select
              v-model="department"
              id=""
              class="com-select form-contorl c-title"
              v-if="departments && departments.length"
              @change="getfilterPosts()"
            >
              <option value="" selected>Department</option>

              <option
                :value="department.id"
                v-for="(department, index) in departments"
                :key="index"
              >
                {{ department.sort_form }}({{ department.full_form }})
              </option>
            </select>
          </div>
          <div class="others-current-job mt-5">
            <div
              class="row justify-content-center"
              v-if="current_posts && current_posts.length"
            >
              <div
                class="col-md-6 col-lg-4 col-xl-3 mb-25"
                v-for="(post, index) in current_posts"
                :key="index"
              >
                <div
                  class="current-job-item"
                  @click="getCareerDetails(post.id)"
                >
                  <div class="box_shadow">
                    <div>
                      <img
                        :src="imageUrl+post.image"
                        alt="job1"
                        loading="lazy"
                      />
                    </div>
                    <div class="job-item-content">
                      <div>
                        <p>{{ post.vacancy_name }}</p>
                        <p>Experience- {{ post.experience }} year</p>
                      </div>
                      <a
                        href="javascript:void(0);"
                        @click="getCareerDetails(post.id)"
                        class="c-title"
                      >
                        View Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- col-md-6 -->
              <!-- <div class="col-md-6 col-lg-4 col-xl-3 mb-25">
                                <div class="current-job-item">
                                    <div class="box_shadow">
                                        <div>
                                            <img src="http://127.0.0.1:8000/assets/frontend/img/career/job2.png" alt="job1" loading="lazy">
                                        </div>
                                        <div class="job-item-content ">
                                            <div>
                                                <p>Web Developer</p>
                                                <p> Experience- 4 year</p>
                                            </div>
                                            <a href="javascript:void(0);" class="c-title">View Details </a>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
              <!-- col-md-6 -->
              <!-- <div class="col-md-6 col-lg-4 col-xl-3 mb-25">
                                <div class="current-job-item">
                                    <div class="box_shadow">
                                        <div>
                                            <img src="http://127.0.0.1:8000/assets/frontend/img/career/job3.png" alt="job3" loading="lazy">
                                        </div>
                                        <div class="job-item-content ">
                                            <div>
                                                <p>Graphic Designe</p>
                                                <p> Experience- 4 year</p>
                                            </div>
                                            <a href="javascript:void(0);" class="c-title"> View Details </a>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
              <!-- col-md-6 -->
              <!-- <div class="col-md-6 col-lg-4 col-xl-3  mb-25">
                                <div class="current-job-item">
                                    <div class="box_shadow">
                                        <div>
                                            <img src="http://127.0.0.1:8000/assets/frontend/img/career/job4.png" alt="job4" loading="lazy">
                                        </div>
                                        <div class="job-item-content ">
                                            <div>
                                                <p>Vedio Editor </p>
                                                <p> Experience- 4 year</p>
                                            </div>
                                            <a href="javascript:void(0);" class="c-title"> View Details </a>
                                        </div>
                                    </div>
                                </div>

                            </div> -->
              <!-- col-md-6 -->
            </div>
            <!-- row end -->
          </div>
          <div
            class="col-md-12 mt-20 text-center"
            v-if="posts.length > initial_length"
          >
            <a href="javascript:void(0);" class="cmn-btn-blue c-title"
              >More jobs</a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- job end -->
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { directive as onClickaway } from "vue-clickaway";
export default {
  directives: {
    onClickaway: onClickaway,
  },
  data: () => ({
    initial_length: 4,
    department: "",
    readMore: false,
    imageUrl: 'https://timerni.com/'
  }),

  async created() {
    this.getDepartments();
    this.getPosts();
  },
  computed: {
    ...mapState("homepage", ["departments"]),
    ...mapState("homepage", ["posts"]),
    ...mapState("homepage", ["current_posts"]),
  },
  methods: {
    ...mapActions("homepage", ["getDepartments"]),
    ...mapActions("homepage", ["getPosts"]),
    getCareerDetails(id) {
      this.$router.push({
        name: "CareerDetails",
        params: { id: id },
      });
    },
    getfilterPosts() {
      this.current_posts = this.posts.filter(
        (post) => post.department_id == this.department
      );
    },
  },
};
</script>

<style>
.com-select {
  background-color: #312783;
  color: var(--white);
  border-radius: 5px;
  padding: 5px 10px;
}
.common-overly.overlay::after {
  z-index: 1;
}

.job-select a.flag-logo {
  color: var(--s-primary-color);
}

.current-job-item .box_shadow {
  padding: 20px 15px;
}

.current-job-item {
  text-align: center;
}

.current-job-item .job-item-content {
  padding-top: 15px;
}
.ambassador-img {
  position: relative;
  display: inline-block;
  padding: 50px 0px;
}

.ambassador-l-o::before,
.ambassador-r-o::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: var(--s-primary-color);
  z-index: -1;
  border-radius: 25px;
}

.ambassador-l-o::before {
  left: -80%;
}

.ambassador-r-o::after {
  right: -80%;
}

.apply-items {
  position: relative;
  cursor: pointer;
}

.apply-items.active {
  position: relative;
  z-index: 9;
}

.apply-content {
  background: rgba(255, 255, 255, 1);
  padding: 25px 25px;
  border-radius: 15px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  position: relative;
  transform: scale(1.2);
  margin-top: 50px;
  display: none;
}

.apply-items.active .apply-content {
  visibility: visible;
  opacity: 1;
  display: block;
}

.apply-items .item-h img {
  border-radius: 10px;
  width: 100%;
}

.apply-items.active .item-h img {
  transform: scale(1.2);
}

.item-h {
  position: relative;
}

.apply-items.active .overlay1::after {
  opacity: 1;
  z-index: 1;
  transform: scale(1.2);
  border-radius: 10px;
}

/* .reward */

.reward > img {
  width: 100%;
}

.reward-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* cv clinic */

.cv-clinic-content li {
  margin-bottom: 20px;
}

.cv-clinic-content li > h3 {
  float: left;
  margin-right: 20px;
  margin-top: -15px;
}

.cv-clinic-content li strong {
  font-size: 30px;
}

.cv-clinic-content li p {
  overflow: hidden;
}
.cv-btn .com-bor-btn:hover {
  color: var(--s-primary-color);
}

/* job */

.common-overly.overlay::after {
  z-index: 1;
}

.job-select a.flag-logo {
  color: var(--s-primary-color);
}

.current-job-item .box_shadow {
  padding: 20px 15px;
}

.current-job-item {
  text-align: center;
}

.current-job-item .job-item-content {
  padding-top: 15px;
}

/* .current-job {
    background-color: #E5E5E5;
} */
</style>
