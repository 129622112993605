<template>
  <main class="job-page-1" v-if="post">
        <div class="page-heading">
            <div class="container">
                <div class="d-flex justify-content-between">

                    <div class="heading">
                        <a href="#">Jobs</a> /

                        <h2 class="job-heading">
                            {{post.vacancy_name}}
                        </h2>
                    </div>
                    <div class="f-right">
                        <a class="cmn-btn-blue" href="javascript:void(0)" @click="careerApplicationform(post.id,post.vacancy_name)">Apply Now!</a>
                    </div>
                </div>
                <div class="mt-4">
                    <p class="black-f t16">
                        {{post.short_description}}
                    </p>
                </div>

            </div>

        </div>

        <section class="task-wrapper mt-4">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-7 mb-25">
                        <h4 class="s-title black-f  mb-3">
                            Skills
                        </h4>
                        <!-- <ul class="disc">
                            <li>
                                <b>Minimum of 2 years working experience in GoLang</b>
                            </li>
                            <li>
                                Minimum of 3 years working experience on Agile projects and strong knowledge of Agile terminology and tools like Asana/JIRA.
                            </li>
                            <li>Must have vast experience with Go test code.</li>
                            <li>Experience in the development of a Billing System including integration with payment gateways such as Stripe, PayPal, or any other recognized payment gateway will be a plus.</li>
                            <li>Database design experience with PostgreSQL.</li>
                            <li>Deep knowledge and clear understanding of design patterns, object-oriented concepts, and implementation.</li>
                            <li>
                                Knowledge of Python and Python frameworks will be a plus.
                            </li>
                            <li>
                                Experience with web service APIs (REST, SOAP, gRPC).
                            </li>
                            <li>
                                Excellent written and verbal communication skills are a must.
                            </li>
                        </ul> -->
                        <div v-html="post.skills"></div>
                    </div>
                    <div class="col-sm-6 col-md-5 mb-25">
                        <h4 class="s-title black-f  mb-3 ">
                            Responsibilities

                        </h4>
                        <!-- <ul class="disc-2">
                            <li>
                                Looking for Golang developer with experience implementing Gin Framework-based enterprise applications.>
                            </li>
                            <li>
                                Support technical systems development team with design, development, implementation, testing, and delivery of solutions for a current international client
                            </li>
                            <li> Ability to develop technical solutions, and document the solution architecture and design using standard design tools</li>

                        </ul> -->
                        <div v-html="post.responsibilities"></div>
                        <h4 class="s-title black-f  mb-3 mt-4">
                            Educational Requirements

                        </h4>
                        <!-- <ul>
                            <li>
                                B.Sc./M.Sc in Computer Science or Software Engineering from any reputed university/Equivalent combination of education and experience may be considered.

                            </li>

                        </ul> -->
                        <div v-html="post.educational_requirements"></div>
                    </div>
                </div>
            </div>
        </section>

        <div class="job-feature">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-2 mb-20">
                        <div class="f-content text-center">
                            <i class="fa fa-tasks fa-5x"></i>
                            <div>
                                <h3 class="mt-10 mb0">Experience Requirements</h3>
                                <p class="text-muted t16">{{post.experience}} Year</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 mb-20">
                        <div class="f-content text-center">
                            <i class="fa fa-search fa-5x"></i>
                            <div v-if="post.vacancy">
                                <h3 class="mt-10 mb0">Vacancy</h3>
                                <p class="text-muted t16">{{post.vacancy}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 mb-20">
                        <div class="f-content text-center">
                            <i class="fa fa-text-height fa-5x"></i>
                            <div>
                                <h3 class="mt-10 mb0">Job Type</h3>
                                <p class="text-muted t16">{{post.job_type}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 mb-20">
                        <div class="f-content text-center">
                            <i class="fas fa-dollar-sign fa-5x"></i>
                            <div>
                                <h3 class="mt-10 mb0">Salary </h3>
                                <p class="text-muted t16">{{post.salary_type == 'Regular' ? post.salary : post.salary_type}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 mb-20">
                        <div class="f-content text-center">
                            <i class="fa fa-th-large fa-5x"></i>
                            <div>
                                <h3 class="mt-10 mb0">Job Category </h3>
                                <p class="text-muted t16">Developer
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="job-facility">
            <div>
                <hr class="border-2">
            </div>
            <div class="container">
                <div class="row justify-content-center mt-25">
                    <div class="col-md-4 mb-20">
                        <div class="f-content text-center">
                            <i class="fa fa-2x fa-heart"></i>
                            <div>
                                <h3 class="mt-10 mb0">Benefits</h3>
                                <p class="t16">{{post.benefits}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-20">
                        <div class="f-content text-center">

                            <i class="fa fa-2x fa-solid fa-sun"></i>
                            <div>
                                <h3 class="mt-10 mb0">PTOs</h3>
                                <p class="t16">{{post.ptos}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-20">
                        <div class="f-content text-center">

                            <i class="fa fa-2x fa-coffee"></i>
                            <div>
                                <h3 class="mt-10 mb0">Eat & Drink</h3>
                                <p class="t16">{{post.eat_drinks}}</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row justify-content-center mt-25">
                    <div class="col-md-4 mb-20">
                        <div class="f-content text-center">
                            <i class="fa fa-2x fa-calendar"></i>
                            <div>
                                <h3 class="mt-10 mb0">Sponsored Events</h3>
                                <p class="t16">{{post.sponsored_events}} </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-20">
                        <div class="f-content text-center">

                            <i class="fa fa-2x fa-solid fa-futbol"></i>
                            <div>
                                <h3 class="mt-10 mb0">Sport Activity</h3>
                                <p class="t16">{{post.sport_activity}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-20">
                        <div class="f-content text-center">

                            <i class="fa fa-2x fa-map-marker"></i>
                            <div>
                                <h3 class="mt-10 mb0">Location</h3>
                                <p class="t16">{{post.location}}</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="job-img" v-if="images && images.length">
            <div class="container">
                <div class="row  mt-25">
                    <div class="col-md-3 mb-2" v-for="(image,img) in images" :key="img">
                        <div>
                            <img :src="'http://timerni.com/'+image.image" alt="ambassador2" loading="lazy">
                        </div>
                    </div>
                    <!-- <div class="col-md-4 mb-2">
                        <div>
                            <img src="http://timerni.com/assets/uploads/project/project-front1651132496.png" alt="ambassador2" loading="lazy">
                        </div>
                    </div>
                    <div class="col-md-4 mb-2">
                        <div>
                            <img src="http://timerni.com/assets/uploads/project/project-front1651132496.png" alt="ambassador2" loading="lazy">
                        </div>
                    </div> -->
                </div>
                <!-- <div class="row mt-25">
                    <div class="col-md-4 mb-2">
                        <div>
                            <img src="http://timerni.com/assets/uploads/project/project-front1651132496.png" alt="ambassador2" loading="lazy">
                        </div>
                    </div>
                    <div class="col-md-4 mb-2">
                        <div>
                            <img src="http://timerni.com/assets/uploads/project/project-front1651132496.png" alt="ambassador2" loading="lazy">
                        </div>
                    </div>
                    <div class="col-md-4 mb-2">
                        <div>
                            <img src="http://timerni.com/assets/uploads/project/project-front1651132496.png" alt="ambassador2" loading="lazy">
                        </div>
                    </div>
                </div> -->
            </div>

        </div>

        <div class="job-hr mt-20">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-center">

                            <i class="fa fa-solid fa-mobile fa-4x"></i>
                            <h4>Contact</h4>
                            <div class="cont-j ">
                                <p >
                                    <i class="fa fa-solid fa-phone mr-1"></i>
                                    <a :href="'tel:'+post.contact">{{post.contact}}</a>
                                    (HR Department)
                                </p>
                            </div>
                            <div class="cont-j ">
                                <p >
                                    <i class="fa fa fa-envelope mr-1"></i>
                                    <a :href="'mailto:'+post.email">{{post.email}}
                                    </a>
                              
                                </p>
                            </div>

                            <div class="mt-25 ">
                                <a class="cmn-btn-blue " href="javascript:void(0)" @click="careerApplicationform(post.id,post.vacancy_name)">Apply Now!</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        


    </main>
    <main class="job-page-1" v-else>
        <div class="d-flex justify-content-center text-center">
            <p>No Posts Found</p>
        </div>
    </main>
  
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name:"CareerDetailsPage",
  data: () => ({
    baseEnvLocal: "https://timerni.com/"
  }),
  
  async created() {
      this.getJobPostDetails();
      this.GalleryImages();
  },
  computed: {
      ...mapState("homepage",["post"]),
      ...mapState("homepage",["images"]),
  }, 
  methods: {
    ...mapActions("homepage",["GalleryImages"]),
    async getJobPostDetails(){
            let id = this.$route.params.id;
            this.$store.dispatch("homepage/getPostDetails",id).then(() => {
            })
            .catch(() => {});
        },
        careerApplicationform(id,name)
        {
            this.$router.push({
            name: 'CareerApplication', 
            params: { id: id,name:name }
            });
        }
    },
    
 };
</script>

<style>
    .page-heading .s-heading {
        color: var(--s-primary-color);
    }
    .s-heading {
        font-weight: 500;
        font-size: 35px;
        line-height: 1.5;
        text-align: center;    
        text-transform: uppercase;
    }
        .page-heading {
            padding-top: 35px;
            padding-bottom: 25px;
        }
    
    </style>
