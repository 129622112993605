<template>
  <CareerApplicationPage />
</template>

<script>
import CareerApplicationPage from '../components/CareerApplicationPage'
import {mapActions,mapState} from "vuex"
export default {
  name: 'CareerApplication',
  components: {
    CareerApplicationPage,
  },
  async created() {

  },
  computed: {
  },
  methods: {

  }
}
</script>
<style>

</style>