import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CareerDetails from '../views/CareerDetails.vue'
import CareerApplication from '../views/CareerApplication.vue'
import App from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: { title: "Home Layout"},
    component: App,
    children: [
      {
        meta: { title: "Home"},
        path: "/",
        component: Home,
      },
      {
        meta: { title: "CareerDetails"},
        path: "/career_details/:id",
        name: "CareerDetails",
        component: CareerDetails,
      },
      {
        meta: { title: "CareerApplication"},
        path: "/career_application/:id/:name",
        name:'CareerApplication',
        component: CareerApplication,
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,  
})
export default router
